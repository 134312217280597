$base-color: #fff;
$disable-background-color: #fff;
.xbtn-primary {
	color: #fff;
	background-color: #FA7B52!important;
	border-color: #FA7B52!important;
}

.xbtn-primary:hover {
	color: #fff;
	background-color: #FA7B52!important;
	border-color: #FA7B52!important;
}
.xbtn-primary:focus,
.xbtn-primary.focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 90, 90, 0.5) !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
	color: #fff;
	background-color: #FA7B52!important;
	border-color: #FA7B52!important;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
	color: #fff;
	background-color: #FA7B52!important;
	border-color: #000;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 90, 90, 0.5) !important;
}

.btn-outline-primary {
	color: teal;
	background-color: transparent;
	background-image: none;
	border-color: #FA7B52!important;
}
.btn-outline-primary:hover {
	color: #222;
	background-color: #FA7B52!important;
	border-color: #FA7B52!important;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.5) !important;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #FA7B52!important;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #FA7B52!important;
	border-color: #FA7B52!important;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.5) !important;
}

.blockbuilder-branding {
	display: none;
}
.multiselect-dropdown {
	position: relative;
	width: 100%;
	font-size: inherit;
	font-family: inherit;
	.dropdown-btn {
		display: inline-block;
		border: 1px solid #adadad;
		width: 100%;
		padding: 6px 12px;
		margin-bottom: 0;
		font-weight: normal;
		line-height: 1.52857143;
		text-align: left;
		vertical-align: middle;
		cursor: pointer;
		background-image: none;
		border-radius: 4px;
		.selected-item {
			border: 1px solid #fff !important;
			margin-right: 4px;
			background: #fff !important;
			padding: 0px 5px;
			color:#444 !important;
			border-radius: 2px;
			float: left;
			a {
				text-decoration: none;
				display: none;
			}
		}
		.selected-item:hover {
			box-shadow: 1px 1px #959595;
		}
		.dropdown-down {
			display: inline-block;
			top: 10px;
			width: 0;
			height: 0;
			border-top: 10px solid #adadad;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
		}
		.dropdown-up {
			display: inline-block;
			width: 0;
			height: 0;
			border-bottom: 10px solid #adadad;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
		}
	}
	.disabled {
		& > span {
			background-color: $disable-background-color;
		}
	}
}

.dropdown-list {
	position: absolute;
	padding-top: 6px;
	width: 100%;
	z-index: 9999;
	border: 1px solid #ccc;
	border-radius: 3px;
	background: #fff;
	margin-top: 2px;
	box-shadow: 0px 1px 5px #959595;
	.no-data {
		h5 {
			font-size: 12px;
		}
	}
	ul {
		padding: 0px;
		list-style: none;
		overflow: auto;
		margin: 0px;
	}
	li {
		padding: 6px 10px;
		cursor: pointer;
		text-align: left;
	}
	.filter-textbox {
		border-bottom: 1px solid #ccc;
		position: relative;
		padding: 10px;
		input {
			border: 0px;
			width: 100%;
			padding: 0px 0px 0px 26px;
		}
		input:focus {
			outline: none;
		}
	}
}

.multiselect-item-checkbox input[type='checkbox'] {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	display: none;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
	border-color: $base-color;
	background-color: #f2f2f2;
	display: none;
}

.multiselect-item-checkbox input[type='checkbox']:active + div:before {
	transition-duration: 0s;
	// display: none;
}

.multiselect-item-checkbox input[type='checkbox'] + div {
	position: relative;
	padding-left: 2em;
	vertical-align: middle;
	user-select: none;
	cursor: pointer;
	margin: 0px;
	color: #000;
	// display: none;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
	box-sizing: content-box;
	content: '';
	color: $base-color;
	position: absolute;
	top: 50%;
	left: 0;
	width: 14px;
	height: 14px;
	margin-top: -9px;
	border: 2px solid $base-color;
	text-align: center;
	transition: all 0.4s ease;
	display: none;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
	box-sizing: content-box;
	content: '';
	background-color: $base-color;
	position: absolute;
	top: 50%;
	left: 4px;
	width: 10px;
	height: 10px;
	margin-top: -5px;
	transform: scale(0);
	transform-origin: 50%;
	transition: transform 200ms ease-out;
	// display: none;
}

.multiselect-item-checkbox input[type='checkbox']:disabled + div:before {
	border-color: #cccccc;
	// display: none;
}

.multiselect-item-checkbox
	input[type='checkbox']:disabled:focus
	+ div:before
	.multiselect-item-checkbox
	input[type='checkbox']:disabled:hover
	+ div:before {
	background-color: inherit;
	display: none;
}

.multiselect-item-checkbox
	input[type='checkbox']:disabled:checked
	+ div:before {
	background-color: #cccccc;
	// display: none;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
	background-color: transparent;
	top: 50%;
	left: 4px;
	width: 8px;
	height: 3px;
	margin-top: -4px;
	border-style: solid;
	border-color: #ffffff;
	border-width: 0 0 3px 3px;
	border-image: none;
	transform: rotate(-45deg) scale(0);
	// display: none;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
	content: '';
	transform: rotate(-45deg) scale(1);
	transition: transform 200ms ease-out;
	// display: none;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
	animation: borderscale 200ms ease-in;
	background: $base-color;
	// display: none;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
	transform: rotate(-45deg) scale(1);
	// display: none;
}

@keyframes borderscale {
	50% {
		box-shadow: 0 0 0 2px $base-color;
	}
}
